import React from "react"
import styled from "styled-components"

const Header = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: max-content;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 52px;
  line-height: 58px;
  text-align: center;
  color: #111111;

  @media (max-width: 768px) {
    width: max-content;
    height: max-content;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 170%;
    line-height: 36px;
    color: #111111;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 22px 0px;
    text-align: left;
  }

  @media (max-width: 475px) {
    width: 308px;
    height: 72px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    color: #111111;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 22px 0px;
    text-align: left;
  }
`

function Title({ children }) {
  return <Header>{children}</Header>
}

export default Title
