import React from "react"
import styled from "styled-components"

const Text = styled.p`
  text-align: ${({ position }) =>
    position === "center" ? "center" : position === "right" ? "right" : "left"};
  width: 100%;
  height: max-content;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #222222;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  margin: 24px 0px;

  @media (max-width: 768px) {
    width: 380px;
    height: 189px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #222222;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 22px 0px;
    text-align: center;
  }

  @media (max-width: 475px) {
    width: 311px;
    height: 189px;
    font-family: Work Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #222222;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 22px 0px;
    text-align: left;
  }
`

function Paragraph({ children, position }) {
  return <Text position={position}>{children}</Text>
}

Paragraph.defaultProps = {
  position: "left",
}

export default Paragraph
