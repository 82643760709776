import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import Title from "../components/shared/Title"
import Paragraph from "../components/shared/paragraph"
import { useEffect } from "react"
import { navigate } from "gatsby"

const Style = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  overflow: hidden;
  margin-top: 24px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 880px;
    box-sizing: border-box;
    margin: 0px 24px;

    .button-group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 0px;
      width: 100%;
      height: max-content;
      flex: none;
      order: 2;
      flex-grow: 0;
      margin: 22px 0px;

      @media (max-width: 731px) {
        flex-direction: column;
      }

      .button-link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 24px;
        border: 2px solid #007de6;
        box-sizing: border-box;
        border-radius: 60px;
        width: 310px;
        height: 60px;
        flex: none;
        order: 1;
        flex-grow: 0;
        margin: 0px 16px;
        text-decoration: none;

        @media (max-width: 731px) {
          margin: 16px 0px;
        }

        .button-text {
          width: max-content;
          height: 27px;
          font-family: Work Sans;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          color: #007de6;
          flex: none;
          order: 0;
          flex-grow: 0;
          margin: 0px 10px;
        }
      }
    }
  }
`

function Download() {
  //test it - navigate is not the best practise - not sure
  function DelayedDownload() {
    navigate(
      "https://1543d3a591e926011c7a-9b1b76dc96f52415d7c2bfd8c3a40309.ssl.cf2.rackcdn.com/PDFProInstaller.exe"
    )
  }

  useEffect(DelayedDownload, [])

  return (
    <Style>
      <Seo title="Download Page" />
      <div className="container">
        <Title>Your download will start in few seconds...</Title>
        <Paragraph position="center">
          You may download your copy of PDF Pro{" "}
          <a href="https://1543d3a591e926011c7a-9b1b76dc96f52415d7c2bfd8c3a40309.ssl.cf2.rackcdn.com/PDFProInstaller.exe">
            here
          </a>{" "}
          .
        </Paragraph>
        <div className="button-group">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.pdfpro.com/hc/en-us/categories/900000414286-Knowledge-Base"
            className="button-link"
          >
            <span className="button-text">Knowledge Base</span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.pdfpro.com/hc/en-us/requests/new"
            className="button-link"
          >
            <span className="button-text">Submit a Support Request</span>
          </a>
        </div>
      </div>
    </Style>
  )
}

export default Download
